
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import Address from "@/domain/model/address/Address.js"
import Company from "@/domain/model/company/Company.js";

import Invoice from "./Invoice.js";
import Card from "./Card.js";

export default class InvoiceSettings extends RcDomainObject {
  
  static MODEL_NAME = Invoice.MODEL_NAME + "Settings";
  
  constructor(domain, data) {
    super(domain, data);
  }
  
  static FIELDS = {
    CONTACT_NAME: "contactName",
    CONTACT_PHONE: "contactPhone",
    CONTACT_EMAIL: "contactEmail",
    COMPANY_NAME: "companyName",
    EMAIL_INVOICE_TO: "emailInvoiceTo",
    PURCHASE_ORDER: "purchaseOrder",
    BILLING_PERIOD: "billingPeriod",
    PAYMENT_METHOD: "paymentMethod",
    CARD_ID: "cardId",
    COMPANY: Company.MODEL_NAME,
    ADDRESS: Address.MODEL_NAME,
    CARD: Card.MODEL_NAME,
    OVERPAYMENT_BALANCE: "overPaymentBalance",
  }
  
  withEmailTo(emails) {
    this.put(InvoiceSettings.FIELDS.EMAIL_INVOICE_TO, emails);
    return this;
  }
  
  withContactName(name) {
    this.put(InvoiceSettings.FIELDS.CONTACT_NAME, name);
    return this;
  }
  
  contactPhone() {
    return this.getString(InvoiceSettings.FIELDS.CONTACT_PHONE);
  }
  
  contactEmail() {
    return this.getString(InvoiceSettings.FIELDS.CONTACT_EMAIL);
  }
  
  withContactPhone(phone) {
    this.put(InvoiceSettings.FIELDS.CONTACT_PHONE, phone);
    return this;
  }
  
  withCompanyName(name) {
    this.put(InvoiceSettings.FIELDS.COMPANY_NAME, name);
    return this;
  }
  
  contactName() {
    return this.getString(InvoiceSettings.FIELDS.CONTACT_NAME);
  }
  
  companyName() {
    return this.getString(InvoiceSettings.FIELDS.COMPANY_NAME);
  }
  
  emailTo() {
    return this.getString(InvoiceSettings.FIELDS.EMAIL_INVOICE_TO);
  }
 
  purchaseOrder() {
    return this.getString(InvoiceSettings.FIELDS.PURCHASE_ORDER);
  }
  
  billingPeriod() {
    return this.getString(InvoiceSettings.FIELDS.BILLING_PERIOD);
  }
  
  address() {
    return new Address(this.domain, this.get(InvoiceSettings.FIELDS.ADDRESS));
  }
  
  company() {
    return new Company(this.domain, this.get(InvoiceSettings.FIELDS.COMPANY));
  }
  
  paymentMethod() {
    var value = this.getString(InvoiceSettings.FIELDS.PAYMENT_METHOD);
    return value;
  }
  
  withPaymentMethod(value) {
    this.put(InvoiceSettings.FIELDS.PAYMENT_METHOD, value);
    return this;
  }
  
  cardId() {
    return this.getString(InvoiceSettings.FIELDS.CARD_ID);
  }
 
  overPaymentBalance() {
    return this.getString(InvoiceSettings.FIELDS.OVERPAYMENT_BALANCE);
  }
 
  card() {
    return new Card(this.domain, this.get(InvoiceSettings.FIELDS.CARD));
  }
 
  find() {
    var settings = this.domain.invoices().settings().findById(this.id());
    return settings;
  }

  copy() {
    var data = this._copy();
    return new InvoiceSettings(this.domain, data);
  }
}