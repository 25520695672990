
import BrowserInspectionStorage from "./BrowserInspectionStorage";

export default class BrowserStorage {
  
  constructor(name) {
    this._inspections = new BrowserInspectionStorage(name);
  }

  inspections() {
    return this._inspections;
  }
  
}