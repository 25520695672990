
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Address from "@/domain/model/address/Address.js";
//import DateUtils  from '@/utils/DateUtils.js';
//import RcDate from '@/domain/session/time/RcDate.js';
import StringUtils from '@/utils/StringUtils.js';

export default class Card extends RcDomainObject {
  
  static MODEL_NAME = "Card";
  
  constructor(domain, data) {
    super(domain, data, Card.MODEL_NAME);
  }
  
  static Fields = {
    NAME: "name",
    NUMBER: "number",
    MONTH: "month",
    YEAR: "year",
    CVC: "cvc",
    ADDRESS : Address.MODEL_NAME,
    INTEGRATION_REFERENCE: "integrationReference",
  }
  
  fields() {
    return Card.FIELDS;
  }
  
  copy() {
    var data = this._copy();
    return new Card(this.domain, data);
  }
  
  find() {
    var invoices = this.domain.invoices();
    var cards = invoices.cards();
    var card = cards.findById(this.id());
    return card;
  }
  
  address() {
    return new Address(this.domain, this.get(Card.Fields.ADDRESS));
  }
  
  integrationReference() {
    return this.get(Card.Fields.INTEGRATION_REFERENCE);
  }
  
  expiry() {
    if (StringUtils.isEmpty(this.month())) {
      return "";
    }
    return this.month() + "/" + this.year();
  }
  
  year() {
    return this.getString(Card.Fields.YEAR);
  }
  
  month() {
    return this.getString(Card.Fields.MONTH);
  }
  
  number() {
    return this.getString(Card.Fields.NUMBER);
  }
  
  name() {
    return this.getString(Card.Fields.NAME);
  }
  
  cvc() {
    return this.getString(Card.Fields.CVC);
  }
  
  withNumber(number) {
    this.put(Card.Fields.NUMBER, number);
    return this;
  }

  withCvc(cvc) {
    this.put(Card.Fields.CVC, cvc);
    return this;
  }

  withYear(year) {
    this.put(Card.Fields.YEAR, year);
    return this;
  }

  withMonth(month) {
    this.put(Card.Fields.MONTH, month);
    return this;
  }
}